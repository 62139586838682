import React from 'react';
import './styles/promotionalMessage.css';

export default function PromotionalMessage() {
  return (
    <div>
      <div className="marquee-container">
        <div className="wrapper">
          <img src='/images/trasnport.svg' style={{ maxWidth: 34 }} alt="Envío gratis por compras superiores a 350.000 COP *Aplica condiciones*" />
          Envío gratis por compras superiores a 350.000 COP *Aplica condiciones*
        </div>
      </div>
    </div>
  );
}
